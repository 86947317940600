import { defineStore } from 'pinia';
import { BikesService } from '@/services/BikesService';
import { TyresService } from '@/services/TyresService';
import { BikeManufacturerItem, BikeEngineCapacity, BikeModelItem } from '@/utils/types/bikes';
import { TyreBikeModelTyreItem, TyreFilterOptionResponse, TyreSearchByBikeModel } from '@/utils/types/tyres';
import { usePromotionStore } from '@/stores/usePromotionStore';

export const useVehicleSearchStore = defineStore('vehicleSearch', {
  state: () => ({
    allManufacturers: [] as BikeManufacturerItem[],
    allCapacities: [] as BikeEngineCapacity[],
    allModels: [] as BikeModelItem[],
    allTypes: [] as string[],
    allTypeModels: [] as BikeModelItem[],
    historyItems: [] as BikeModelItem[],
    manufacturerModels: [],

    selectedManufacturer: '',
    selectedCapacity: '',
    selectedModel: '',

    tyreData: [] as TyreBikeModelTyreItem[],
    tyreDataPaginations: [] as TyreBikeModelTyreItem[],
    tyreDataCount: 0,

    filterTyreFilterOptions: null as TyreFilterOptionResponse | null,
  }),
  actions: {
    async getAllManufacturers() {
      const response = await BikesService.getManufacturers();
      if (response.status === 200) {
        this.allManufacturers = response.data;
      }
      return response;
    },
    async getAllCapacities() {
      const response = await BikesService.getCapacities();
      if (response.status === 200) {
        this.allCapacities = response.data;
      }
      return response;
    },
    async getModelsManufacturer(manufacturer: string, ccmFrom: number, ccmTo: number) {
      const response = await BikesService.getModelsManufacturer(manufacturer, ccmFrom, ccmTo);
      if (response.status === 200) {
        this.allModels = response.data;
      }
      return response;
    },
    async getAllTypes() {
      const response = await BikesService.getTypes();
      if (response.status === 200) {
        this.allTypes = response.data;
      }
      return response;
    },
    async getModelsByBikeType(type: string) {
      const response = await BikesService.getModelsByBikeType(type);
      if (response.status === 200) {
        this.allTypeModels = response.data;
      }
      return response;
    },
    async getBikesSearchHistory() {
      const response = await BikesService.getBikesSearchHistory();
      if (response.status === 200) {
        this.historyItems = response.data;
      }
      return response;
    },
    async getTyresByBikeModelFirstPage(searchPayload: TyreSearchByBikeModel) {
      const response = await TyresService.getTyresByBikeModel({
        ...searchPayload,
        page: 1,
      });
      if (response.status === 200) {
        this.tyreData = response.data.items;
        this.tyreDataCount =  response.data.fullCount;
        this.tyreDataPaginations = response.data.items;

        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);

        this.filterTyreFilterOptions = {
          ...response.data.filter,
          stock: [
            {
              key: 'allStock',
              name: 'ALL-STOCK',
              value: null,
            },
            {
              key: 'withStock',
              name: 'WITH-STOCK',
              value: true,
            },
          ],
        };
      }
      return response;
    },
    async getTyresByBikeModelPagination(searchPayload: TyreSearchByBikeModel) {
      const response = await TyresService.getTyresByBikeModel(searchPayload);
      if (response.status === 200 && response.data.items.length === 0) {
        return Promise.reject('noresult');
      }
      if (response.status === 200) {
        this.tyreDataPaginations = [
          ...this.tyreDataPaginations,
          ...response.data.items,
        ];
        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);
      }
      return response;
    },
    updateTyreDataPaginations(data: TyreBikeModelTyreItem[]) {
      this.tyreDataPaginations = data;
    },
    async changeBikeManufacturerFavoriteState(data: BikeManufacturerItem) {
      const response = await BikesService.changeBikeManufacturerFavoriteState(data);
      if (response.status === 200) {
        const editedFavoriteData = this.allManufacturers.slice().map((item) => {
          if (item.key === data.key) {
            return {
              ...item,
              isFavorite: data.isFavorite,
            };
          } else {
            return item;
          }
        });
        this.allManufacturers = editedFavoriteData;
      }
      return response;
    },
  },
});
